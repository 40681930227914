<template>
  <g :fill="color" fill-rule="nonzero">
    <path d="M10 12.012V2a1 1 0 1 0-2 0v10.012a1 1 0 1 0 2 0z" />
    <path d="M12.197 3.136L9.53.47a.75.75 0 0 0-1.06 1.06l2.666 2.667a.75.75 0 0 0 1.061-1.06z" />
    <path d="M8.47.47L5.803 3.136a.75.75 0 1 0 1.06 1.061L9.53 1.53A.75.75 0 0 0 8.47.47z" />
    <path
      d="M15.857 5.75c.226 0 .393.16.393.34v9.82c0 .18-.167.34-.393.34H2.143c-.226 0-.393-.16-.393-.34V6.09c0-.18.167-.34.393-.34a.75.75 0 1 0 0-1.5C1.104 4.25.25 5.065.25 6.09v9.82c0 1.025.854 1.84 1.893 1.84h13.714c1.039 0 1.893-.815 1.893-1.84V6.09c0-1.025-.854-1.84-1.893-1.84a.75.75 0 1 0 0 1.5z"
    />
  </g>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
